@import './../../vars.scss';

.appBarComponent {

  header {
    background-color: #fff !important;
  }

  .navbar {
    &__inner {
      margin: 0 auto;
      max-width: 1800px;
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
    }

    &__items {
      align-items: center;
      display: flex;
    }

    &__item {
      font-weight: 300;
    }

    &__link {
      font-size: 14px;
      color: black;
      position: relative;
      text-decoration: none;
      font-weight: 500;

      p {
        margin-right: 10px;
      }
    }

    .right {
      text-align: right;
    }
  }

  .lang {
    cursor: pointer;
  }

  .logo {
    width: 100%;
    max-width: 50px;
    margin-top: 3px;
    background-repeat: no-repeat;
    background-size: auto;
  }

  .icon {
    width: 20px;
  }

  .iconkart {
    width: 27px;
  }

  .MuiButton-root {
    color: #fff;
    padding: 6px 16px;
    font-size: 1rem !important;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 10px !important;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
  }

  .css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #0C88E0 !important;
    box-shadow: none !important;
  }

  .espiral-blue {
    color: $blue-espiral;
  }

  .center {
    display: flex;
    align-items: center;
  }

  .css-lozntj-MuiButtonBase-root-MuiButton-root {
    background-color: #14abf7 !important;
    font-weight: 600 !important;
    padding: 8px 35px !important;
    box-shadow: none !important;

    &:hover {
      background-color: $blue-espiral !important;
    }
  }

  .css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
    color: #14abf7 !important;
  }

  a {
    .small-font {
      font-size: .8rem !important;
    }
  }

  .small-doc-blue {
    font-size: .9rem !important;
    color: #14abf7;
  }

  @media (min-width: 1800px) {
    .css-19r6kue-MuiContainer-root {
      max-width: 1800px;
    }
  }
}
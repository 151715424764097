.doc-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 0 50px ;
    overflow: auto;

    table {
        border-collapse: collapse;
        overflow: scroll;
        max-width: 800px;
        min-width: 320px;
    }
    
    table, th, td {
        border: 1px solid #dadde1;
        padding: 8px;
    }
    
    th {
        color: #7a7a7a;
        font-weight: 700;
        padding: 12px;
    }
    
    tr:nth-child(even){
        background-color: #f2f2f2;
    }
    
    h1,
    h2,
    h3,
    p {
        text-align: left;
    }
    
    h1 {
        font-size: 2.8rem;
    }
    
    h2 {
        font-size: 2rem;
    }
    
    h3 {
        font-size: 1.6rem;
    }
    
    a,
    a:visited {
        color: #14abf7;
        text-decoration: none;
    }
    
    a.navbar__link,
    a.navbar__link:visited {
        color: white;
    }
    
    
    pre {
        border-radius: 10px;
        margin-top: 20px !important;
    }

    li {
        padding: 5px 0;
        line-height: 1.7;
    }

    p {
        line-height: 1.7;
    }

}

#respEco {
    position: fixed;
    top: 120px;
    left: 0;   
}

.arrow { display: none; }

.sideBarEco {
    box-sizing: border-box;
    width: 100%;
    max-width: 20rem;
    height: 100%;
    border-right: 1px solid #dadde1;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    overflow-x: hidden;
    transition: 0.5s;

    a,
    a:visited {
        color:#7a7a7a;
        text-decoration: none;
        transition: 0.3s;
    }

    .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }

    ul {
        list-style-type: none;
    }
    
    li {
        padding: 5px 5px 5px 15px;
    
        &:hover,
        &:active {
            background-color: #f2f2f2;
            border-left: 3px solid #0b6da8;
        }
    
        &.title {
            font-size: 15px;
            font-weight: 600;
        }
    }

    .item {
        padding-left: 25px;
    }
}

@media only screen and (max-width: 1366px){
    .sideBarEco {
        max-width: 15rem !important;
        background-color: #fff;
        position: absolute;

        li {
            padding: 5px 15px 5px 5px !important;
        }
    }

    .arrow {
        display: absolute ;
        top: -25px;
        left: 20px;
    }

    #respEco {
        top: 71px;
        left: -15rem;
    }

    .css-i4bv87-MuiSvgIcon-root {
        fill: #1976d2 !important;
    }
}

@media only screen and (max-width: 1367px) {
    .sideBarEco {
        max-width: 15rem !important;    
    }
}

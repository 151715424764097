.GrandBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 35px 50px;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    text-decoration: none;
    color: black;
    font-weight: 600;
    transition: transform .2s ease-in-out;
    margin: 25px;
    z-index: 7;
    background-color: white;

    &-mpos {
        box-shadow: 0px 0px 20px 0px rgba(170,107,206,0.2);
        &:hover {
            box-shadow: 0px 0px 20px 20px rgba(170,107,206,0.2);
        }
    }

    &-ecommerce {
        box-shadow: 0px 0px 20px 0px rgba(98,204,174,0.2);
        &:hover {
            box-shadow: 0px 0px 20px 20px rgba(98,204,174,0.2);
        }
    }

    &:visited {
        color: black;
        text-decoration: none;
    }

    &:hover {
        transform: scale(1.2);
    }

    &:active {
        transform: scale(1) translateY(0);
    }
}

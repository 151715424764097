.App {
  margin: 0 auto;
  .overlay-container {
    position: relative;
    overflow: hidden;

    .img-overlay {
      position: absolute;
      z-index: 1;
      top: -15px;
      right: 0;
      width: 28%;
    }
  }

  .section {
    &-header {
      box-sizing: border-box;
      text-align: center;
      height: 380px;
      background: rgb(12,109,168);
      background: linear-gradient(0deg, rgba(12,109,168,1) 0%, rgba(12,136,224,1) 100%);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      .text {
        line-height: .7;
        z-index: 2;
      }

      h1 {
        font-size: 3.5rem;
      }
    }

    &-links {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      min-height: 500px;
      padding: 25px 0;
      max-width: 1440px;
      margin: auto;
    }
  }
}

a {
  text-decoration: none
}

@media only screen and (max-width: 600px) {
  .App {
    .overlay-container {
      .img-overlay {
        display: none;
      }
    }

    .section {
      &-header {
        box-sizing: border-box;
        text-align: center;
        height: 380px;
        background: rgb(12,109,168);
        background: linear-gradient(0deg, rgba(12,109,168,1) 0%, rgba(12,136,224,1) 100%);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;

        .text {
          line-height: 1;
        }
      }
    }
  }
}

@media only screen and (min-width: 820px) {
  .App {
    .overlay-container {
      .img-overlay {
        top: 30%;
        right: 0;
        width: 47%;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .App {
    .overlay-container {
      .img-overlay {
        top: 20%;
        right: 0;
        width: 35%;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .App {
    .overlay-container {
      .img-overlay {
        position: absolute;
        z-index: 1;
        top: -15px;
        right: 0;
        width: 28%;
      }
    }
  }
}

@media only screen and (min-width: 2000px) {
  .App {
    .overlay-container {
      .img-overlay {
        position: absolute;
        z-index: 1;
        top: 10%;
        right: 0;
        width: 17%;
      }
    }
  }
}

@import './vars.scss';

//  ██████╗ ██████╗ ██╗      ██████╗ ██████╗ ███████╗     ██████╗██╗      █████╗ ███████╗███████╗
// ██╔════╝██╔═══██╗██║     ██╔═══██╗██╔══██╗██╔════╝    ██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
// ██║     ██║   ██║██║     ██║   ██║██████╔╝███████╗    ██║     ██║     ███████║███████╗███████╗
// ██║     ██║   ██║██║     ██║   ██║██╔══██╗╚════██║    ██║     ██║     ██╔══██║╚════██║╚════██║
// ╚██████╗╚██████╔╝███████╗╚██████╔╝██║  ██║███████║    ╚██████╗███████╗██║  ██║███████║███████║
//  ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝     ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝
                                                                                              
.back-blue-dark-beluga {
  background-color: $blue-dark-beluga !important;
}

.text-green-dark-beluga {
  color: $green-espiral !important;
}

.text-blue-dark-beluga {
  color: $blue-espiral !important;
}

.text-blue-light-beluga {
  color: $blue-light-beluga !important;
}

.text-white {
  color: white;
}

// ████████╗███████╗██╗  ██╗████████╗     ██████╗ ██████╗ ███╗   ██╗███████╗██╗ ██████╗ 
// ╚══██╔══╝██╔════╝╚██╗██╔╝╚══██╔══╝    ██╔════╝██╔═══██╗████╗  ██║██╔════╝██║██╔════╝ 
//    ██║   █████╗   ╚███╔╝    ██║       ██║     ██║   ██║██╔██╗ ██║█████╗  ██║██║  ███╗
//    ██║   ██╔══╝   ██╔██╗    ██║       ██║     ██║   ██║██║╚██╗██║██╔══╝  ██║██║   ██║
//    ██║   ███████╗██╔╝ ██╗   ██║       ╚██████╗╚██████╔╝██║ ╚████║██║     ██║╚██████╔╝
//    ╚═╝   ╚══════╝╚═╝  ╚═╝   ╚═╝        ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝ ╚═════╝ 

.light {
  font-weight: 200 !important;
}

.normal {
  font-weight: 400 !important;
}

.semi-bold {
  font-weight: 500 !important;
}

.bold {
  font-weight: 700 !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.position-relative {
  position: relative;
}

// ██████╗ ██╗   ██╗████████╗████████╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║   ██║╚══██╔══╝╚══██╔══╝██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔██╗ ██║███████╗
// ██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╗██║╚════██║
// ██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚████║███████║
// ╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═══╝╚══════╝
        

.MuiButton-outlined {
  border: 5px solid #fff !important;
  padding: 10px 50px !important;
}

.MuiButton-root {
  color: #fff !important;
  padding: 6px 16px;
  font-size: 1.5rem !important;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 20px !important;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.btn {
  display: inline-block;
  padding: .3em 1.1em;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 100;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  cursor: pointer;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.btn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
}

.btn-blue-dark {
  background-color: $blue-dark-beluga;
  color: white;
  transition: all 250ms !important;

  &.btn-blue-dark:hover {
    background-color: white;
    color: $blue-dark-beluga;
  }
}

.btn-header {
  font-size: 1.5em;
}
